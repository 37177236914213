import { api } from '.';
import { isCallSwitch, isPulseHd, isYay } from '../../helpers';
import {
    ApiGlobalSetting,
    Department,
    IAccount,
    IAuthUser,
    ICallRoute,
    ICRMIntegration,
    IHuntGroup,
    IMailboxMenu,
    IPhonebook,
    IPhoneNumber,
    IShortcode,
    ISound,
    ITimeDiary,
    IUser
} from '../../types';
import { CompanyDetailsProps } from '../../pages/auth';
import { selectCurrentUserSipName, selectUserStatus, updateUserStatus } from '../slices';

export interface ILoginResponse {
    auth: IAuthUser;
    call_routes: ICallRoute[];
    caller_ids: null;
    hunt_groups: IHuntGroup[];
    numbers: IPhoneNumber[];
    parking_slots: null;
    phone_books: IPhonebook[];
    selectable_users: [];
    selectable_accounts: IAccount[];
    matched_accounts: IAccount[];
    short_codes: IShortcode[];
    time_diaries: ITimeDiary[];
    integrations: ICRMIntegration[] | undefined;
    users: IUser[];
    sounds: ISound[];
    mailboxes: IMailboxMenu[];
    departments: Department[];
    is_aus?: boolean;
}

export const authApi = api.injectEndpoints({
    endpoints: builder => ({
        getDeviceLinkCode: builder.query<
            { login_result: ILoginResponse } | undefined,
            {
                link_code: string;
            }
        >({
            query: ({ link_code }) => ({
                method: 'GET',
                url: '/check-device-link-code',
                params: {
                    link_code
                }
            })
        }),
        getDeploymentCodeCheck: builder.query<{ result: CompanyDetailsProps }, string>({
            query: code => ({
                method: 'GET',
                url: '/check-deployment-code',
                params: {
                    code
                }
            })
        }),
        getDeploymentStatus: builder.query<ILoginResponse | undefined, undefined>({
            query: () => ({
                method: 'GET',
                url: '/check-deployment'
            })
        }),
        getAccountUser: builder.query<
            ILoginResponse,
            {
                deviceToken: string | undefined;
                deviceUuid: string | undefined;
                userUuid: string | undefined;
            }
        >({
            query: ({ deviceToken, deviceUuid, userUuid }) => ({
                method: 'GET',
                url: '/select-account-user',
                params: {
                    device_token: deviceToken,
                    device_uuid: deviceUuid,
                    user_uuid: userUuid
                }
            })
        }),
        getSsoCredentials: builder.query<{ google?: string; microsoft?: string }, number>({
            query: platform => ({
                method: 'GET',
                url: '/get-sso-credentials',
                params: {
                    platform
                }
            })
        }),

        /**
         * POST Requests
         */
        checkUserAgent: builder.mutation<any, { voip_user_uuid: string; site_version: string }>({
            query: ({ voip_user_uuid, site_version }) => ({
                method: 'POST',
                url: '/check-agent',
                params: {
                    voip_user_uuid,
                    site_version,
                    app_id: (isYay && 1) || (isCallSwitch && 4) || 2
                }
            })
        }),
        postMobileLogin: builder.mutation<undefined, string>({
            query: mobile => ({
                method: 'POST',
                url: '/api/start-mobile-confirmation',
                params: {
                    type_override: 'mobile'
                },
                data: {
                    app_id: (isCallSwitch && 4) || (isPulseHd && 2) || 1,
                    device_platform: 3,
                    mobile_number: mobile,
                    require_invite: false
                }
            })
        }),
        postGenerateDeviceLink: builder.mutation<
            {
                result: {
                    link_code: string;
                    qr_code_string: string;
                    expires_on: string;
                };
            },
            { device_nickname: string }
        >({
            query: ({ device_nickname }) => ({
                method: 'POST',
                url: '/generate-device-link',
                data: {
                    app_id: (isYay && 1) || (isCallSwitch && 4) || 2,
                    device_platform: 3,
                    device_nickname
                }
            })
        }),
        postConfirmationCode: builder.mutation<
            | {
                  login_result: ILoginResponse;
              }
            | {
                  unknown_number: boolean;
              }
            | {
                  selectable_accounts: IAccount[];
              },
            { mobileNumber: string; confirmationCode: string }
        >({
            query: ({ mobileNumber, confirmationCode }) => ({
                method: 'POST',
                url: '/check-confirmation-code',
                params: {
                    type_override: 'mobile'
                },
                data: {
                    app_id: (isYay && 1) || (isCallSwitch && 4) || 2,
                    device_platform: 3,
                    mobile_number: mobileNumber,
                    activation_code: confirmationCode
                }
            })
        }),
        postRequestDeployment: builder.mutation<
            ILoginResponse | undefined,
            {
                companyCode: string;
                emailAddress: string;
                name: string;
                password: string;
            }
        >({
            query: ({ companyCode, emailAddress, name, password }) => ({
                method: 'POST',
                url: '/request-deployment',
                data: {
                    name,
                    email_address: emailAddress,
                    code: companyCode,
                    password
                }
            })
        }),
        postCreateAccount: builder.mutation<
            undefined,
            {
                name: string;
                email: string;
                mobileNumber: string;
                password: string;
            }
        >({
            query: ({ name, email, mobileNumber, password }) => ({
                method: 'POST',
                url: '/signup',
                data: {
                    first_name: name,
                    email_address: email,
                    voice: mobileNumber,
                    password
                }
            })
        }),
        postLoginWithEmailAndPassword: builder.mutation<
            ILoginResponse,
            {
                email: string;
                password: string;
            }
        >({
            query: ({ email, password }) => ({
                method: 'POST',
                url: '/login',
                params: {
                    supports_multi: isCallSwitch || isPulseHd || undefined
                },
                data: {
                    email_address: email,
                    password,
                    app_name: (isPulseHd && 'pulsehd') || (isCallSwitch && 'callswitchone') || 'yay'
                }
            })
        }),
        postLoginWithSso: builder.mutation<
            ILoginResponse,
            {
                provider: string;
                access_token: string;
            }
        >({
            query: ({ provider, access_token }) => ({
                method: 'POST',
                url: '/login',
                params: {
                    supports_multi: isCallSwitch || isPulseHd || undefined
                },
                data: {
                    provider,
                    access_token
                }
            })
        }),
        getLoginWithToken: builder.query<ILoginResponse, null>({
            query: () => ({
                method: 'GET',
                url: '/login'
            })
        }),
        getUser: builder.query<
            {
                user: IUser;
                devices: {
                    deviceId: string;
                    label: string;
                }[];
            },
            { voip_user_uuid: string; site_version: string }
        >({
            query: ({ voip_user_uuid, site_version }) => ({
                method: 'GET',
                url: '/api/get-specific-sip-user',
                params: {
                    voip_user_uuid,
                    site_version,
                    app_id: (isYay && 1) || (isCallSwitch && 4) || 2
                }
            }),
            transformResponse: async (response: { result: IUser }) => {
                const devices = await navigator.mediaDevices
                    .enumerateDevices()
                    .then(enumerateDevices =>
                        enumerateDevices.flat().map(device => ({
                            deviceId: device.deviceId,
                            label: device.label
                        }))
                    )
                    .catch(() => []);

                return { user: response.result, devices };
            }
        }),

        /**
         * PUT Requests
         */
        putInviteComplete: builder.mutation<
            any,
            {
                tokenId: string;
                email: string;
                password: string;
            }
        >({
            query: ({ tokenId, email, password }) => ({
                method: 'PUT',
                url: '/invite',
                params: {
                    invite_token: tokenId,
                    email
                },
                data: {
                    password
                }
            })
        }),
        putUserSettings: builder.mutation<
            any,
            {
                uuid: string;
                newAipGlobalSettings: ApiGlobalSetting[];
            }
        >({
            query: ({ uuid, newAipGlobalSettings }) => ({
                method: 'PUT',
                url: '/api/put-sip-user-settings',
                params: {
                    voip_user_uuid: uuid
                },
                data: {
                    global_settings: newAipGlobalSettings
                }
            })
        }),
        putUserGlobalDnd: builder.mutation<
            { result: { dnd: boolean } },
            {
                uuid: string;
                dnd: boolean;
            }
        >({
            query: ({ uuid, dnd }) => ({
                method: 'PUT',
                url: '/api/put-sip-user-global-dnd',
                params: {
                    voip_user_uuid: uuid
                },
                data: {
                    dnd
                }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                const {
                    data: { result }
                } = await queryFulfilled;

                if (result === undefined) {
                    return;
                }

                try {
                    const state = getState();

                    const currentUserSipName = selectCurrentUserSipName(state);
                    const status = selectUserStatus(state, currentUserSipName);

                    if (!status || !currentUserSipName) return;

                    dispatch(
                        updateUserStatus({
                            ...status,
                            global_dnd: result.dnd
                        })
                    );
                } catch (e) {
                    //     Validation Failed
                }
            }
        })

        /**
         * DELETE Requests
         */
    })
});

export const {
    usePostMobileLoginMutation,
    usePostGenerateDeviceLinkMutation,
    useGetDeviceLinkCodeQuery,
    useGetDeploymentCodeCheckQuery,
    useGetDeploymentStatusQuery,
    usePostLoginWithEmailAndPasswordMutation,
    useGetLoginWithTokenQuery,
    usePostCreateAccountMutation,
    useGetUserQuery,
    useGetAccountUserQuery,
    usePostConfirmationCodeMutation,
    usePostRequestDeploymentMutation,
    usePostLoginWithSsoMutation,
    usePutInviteCompleteMutation,
    usePutUserSettingsMutation,
    usePutUserGlobalDndMutation,
    useGetSsoCredentialsQuery,
    useCheckUserAgentMutation
} = authApi;
